.App {
  text-align: center;
  background-color: #000;
}

.App-navbar {
  height: 50px;
  width: 100%;
  background: white;
}

.brand {
  line-height: 50px;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #444;
}

.App-content {
  background-color: #000;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
